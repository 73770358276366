export const Rules = [
  { label: "Admin", value: "admin" },
  { label: "Rules", value: "rules" },
  {label: 'Social Media', value: 'social_media'},
  {label: 'Home', value: 'home'},
  {label: 'MasterHN', value: 'masterHN'},
  // { label: "Marketing", value: "marketing" },
  // { label: "Seo - pixel", value: "seo_pixel" },
  // { label: "Branding", value: "branding" },
  // { label: "Design", value: "design" },
  // { label: "Products", value: "products" },
  // { label: "KYC Requests", value: "kyc_requests" },
  // {label: 'Order', value: 'order'},
  // {label: 'Promo Code', value: 'promo_code'},
  // {label: 'Categories', value: 'categories'},
  // {label: 'SubCategories', value: 'sub_categories'},
  // {label: 'Variant', value: 'variant'},
  // {label: 'Control', value: 'control'},
  // {label: 'Currency', value: 'currency'},
  // {label: 'Notification', value: 'notification'},
  // {label: 'TimeSlot', value: 'time_slot'},
  // {label: 'Static Pages', value: 'static_pages'},
];
